import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React, {useEffect} from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useCustomerByNumber} from '../../../hooks/useCustomer'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {OrgUnit} from '../WidgetUserCreation.types'

import {WidgetCustomerSearchInput} from './WidgetCustomerSearchInput'
import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetCustomerInputProps = WidgetInputsProps & {
  selectedCustomerNumber?: string
  setSelectedCustomerNumber: (selectedCustomerId?: string) => void
  selectedOrgUnit?: OrgUnit
  cscData?: RoleAssignment
  setSelectedCustomerId: (customerId?: string) => void
}
export const WidgetCustomerInput = ({
  formMethods,
  selectedCustomerNumber,
  setSelectedCustomerNumber,
  selectedOrgUnit,
  cscData,
  setSelectedCustomerId
}: WidgetCustomerInputProps) => {
  const {t} = useTranslation()

  const {
    data: customersData,
    isInitialLoading: isLoadingCustomersData,
    isError: isCustomersDataError
  } = useCustomerByNumber(
    selectedCustomerNumber || '',
    (cscData?.dataScope['countryId'] as string) || '',
    selectedOrgUnit?.value || '',
    (cscData?.dataScope['businessLine'] as string) || '',
    !!selectedCustomerNumber
  )

  useEffect(() => {
    if (customersData && customersData?.length > 0) {
      setSelectedCustomerId(customersData[0].customerId)
    }

    if (customersData && customersData?.length === 0) {
      setSelectedCustomerId(undefined)

      formMethods.setError('customerId', {
        message: t('widgetUserCreation.form.errors.customerNoResults')
      })
    }

    if (isCustomersDataError) {
      setSelectedCustomerId(undefined)
      formMethods.setError('customerId', {
        message: t('widgetUserCreation.form.errors.customerDataError')
      })
    }
  }, [customersData, isCustomersDataError])

  return (
    <Controller
      name="customerId"
      control={formMethods.control}
      rules={{
        required: {
          value: true,
          message: t('widgetUserCreation.form.errors.required', {
            fieldName: t('widgetUserCreation.form.customerId')
          })
        }
      }}
      render={({field}) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <WidgetCustomerSearchInput
            handleSubmit={() => {
              setSelectedCustomerNumber(field.value)
              if (selectedCustomerNumber === field.value) {
                if (isCustomersDataError) {
                  formMethods.setError('customerId', {
                    message: t('widgetUserCreation.form.errors.customerDataError')
                  })
                }
                if (customersData?.length === 0) {
                  formMethods.setError('customerId', {
                    message: t('widgetUserCreation.form.errors.customerNoResults')
                  })
                }
              }
            }}
            onChange={(event) => field.onChange(event.currentTarget.value)}
            onKeyUp={(event: React.KeyboardEvent) => {
              if (event.key === 'Enter') {
                setSelectedCustomerNumber(field.value)
                if (selectedCustomerNumber === field.value) {
                  if (isCustomersDataError) {
                    formMethods.setError('customerId', {
                      message: t('widgetUserCreation.form.errors.customerDataError')
                    })
                  }
                  if (customersData?.length === 0) {
                    formMethods.setError('customerId', {
                      message: t('widgetUserCreation.form.errors.customerNoResults')
                    })
                  }
                }
              }
            }}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
              const {key} = event
              if ((key === 'Backspace' || key === 'Delete') && field.value.length === 1) {
                setSelectedCustomerNumber('')
              }
            }}
            value={field.value}
            label="Customer ID"
            disabled={!selectedOrgUnit}
            error={!!formMethods.formState.errors.customerId}
            loading={isLoadingCustomersData}
            customerResult={customersData}
            setSelectedCustomerNumber={setSelectedCustomerNumber}
          />
          {formMethods.formState.errors.customerId && (
            <Typography
              variant="caption"
              style={{color: 'rgba(218, 9, 1, 1)', padding: '8px 8px', marginBottom: '4px'}}
            >
              {formMethods.formState.errors.customerId.message}
            </Typography>
          )}
        </Box>
      )}
    />
  )
}
