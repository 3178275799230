import {makeStyles} from 'tss-react/mui'

export const useWidgetUserCreationStyles = makeStyles()(() => ({
  submitButton: {
    color: '#FFFFFF',
    backgroundColor: '#016AD4',
    borderRadius: '4px',
    padding: '12px 16px',
    marginTop: '48px',
    '&:disabled': {
      backgroundColor: '#016AD4',
      opacity: 0.5,
      color: '#FFFFFF'
    },
    '&:hover': {
      backgroundColor: '#016AD4',
      opacity: 0.9
    }
  },
  progress: {
    color: '#FFFFFF'
  },
  discardButton: {
    color: '#016AD4',
    marginTop: '48px',
    fontWeight: 500,
    letterSpacing: 0
  },
  trackEnabled: {
    backgroundColor: '#016AD4 !important'
  },
  selectDropdown: {
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    width: '100%',
    maxWidth: '100%',
    marginBottom: '12px',
    marginTop: '4px'
  },
  dropdownTextField: {
    '& .MuiInputBase-root': {
      marginLeft: '8px'
    },
    '& .MuiInputLabel-formControl': {
      left: '8px',
      color: '#00374D'
    }
  }
}))
