import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  searchInput: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      border: '1px solid #D1D9E0',
      borderRadius: '4px'
    },
    '& .MuiInputBase-input': {
      padding: '26px 8px 8px 8px'
    },
    '& .Mui-disabled': {
      backgroundColor: '#e5e5ec !important'
    },
    '&.Mui-focused': {
      border: '1px solid #D1D9E0'
    }
  },
  searchInputError: {
    '& .MuiInputBase-root': {
      borderColor: 'rgba(218, 9, 1, 1)'
    }
  },
  searchInputLabel: {
    color: '#00374d',
    left: '-4px',
    top: '-4px',
    border: 'none'
  }
}))
