import {Product} from '@hconnect/apiclient'
import {Typography} from '@hconnect/uikit'
import {TextField} from '@material-ui/core'
import {Box} from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {handleAllowedCharacters} from '../../../common/utils/handleAllowedCharacters'
import {useStyles} from '../../../components/UserInformation/inputs/UserInput.styles'

import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetEmailInputProps = WidgetInputsProps & {
  selectedProducts?: Product[]
}
export const WidgetEmailInput = ({formMethods, selectedProducts}: WidgetEmailInputProps) => {
  const {t} = useTranslation()
  const inputClasses = useStyles()
  const mobileNumberValue = formMethods.watch('mobileNumber')

  const handleValidation = (value: string) => {
    if (!value) {
      if (!!selectedProducts?.find((product) => product === Product.Hub) && !value) {
        return t('widgetUserCreation.form.errors.hubEmailRequired')
      }

      if (!mobileNumberValue) {
        return t('widgetUserCreation.form.errors.emailOrMobileNumber')
      }

      return true
    } else if (value) {
      return handleAllowedCharacters(value, t('errorMessages.EMAIL_FORMAT_NOT_ALLOWED'))
    }

    return true
  }

  return (
    <Controller
      name="email"
      control={formMethods.control}
      rules={{
        validate: (value) => handleValidation(value)
      }}
      render={({field}) => (
        <Box style={{display: 'flex', flexDirection: 'column'}}>
          <TextField
            {...field}
            onChange={(event) => {
              field.onChange(event)
              if (formMethods.formState.errors.email?.type === 'validate') {
                formMethods.clearErrors('email')
              }

              if (formMethods.formState.errors.mobileNumber) {
                formMethods.trigger('mobileNumber')
              }
            }}
            data-test-id={`widgetUserCreation-${field.name}`}
            className={clsx({
              [inputClasses.quickPreviewTextField]: true,
              [inputClasses.quickPreviewTextFieldError]: !!formMethods.formState.errors.email
            })}
            fullWidth
            id="email"
            label={t('widgetUserCreation.form.email')}
            type="email"
            autoComplete="email"
            margin="normal"
            error={!!formMethods.formState.errors.email}
            InputProps={{disableUnderline: true}}
            InputLabelProps={{shrink: true}}
          />
          {!!formMethods.formState.errors.email && (
            <Typography
              variant="caption"
              style={{color: 'rgba(218, 9, 1, 1)', padding: '0 8px', marginBottom: '4px'}}
            >
              {formMethods.formState.errors.email?.message}
            </Typography>
          )}
        </Box>
      )}
    />
  )
}
