import {Content, LoadingButton, Page, Typography} from '@hconnect/uikit'
import {Add, Launch} from '@mui/icons-material'
import {Box, Button} from '@mui/material'
import {isEmpty} from 'lodash'
import React, {useEffect, useMemo, useState} from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {useParams} from 'react-router'

import {useUser} from '../../common/hooks/useUser'
import {useUserRoles} from '../../common/hooks/useUserRoles'
import {UserInformationFormData} from '../../components/UserInformation/UserInformationForm.types'
import {selectLoggedInUserProfile} from '../../modules/LoggedInUser.selectors'
import {RoleAssignment, RoleType} from '../../modules/ManageUsers.selectors'
import {useUpdateUser} from '../ManageUser/hooks/useUpdateUser'
import {WidgetCustomerInput} from '../WidgetUserCreation/components/WidgetCustomerInput'
import {WidgetOrgUnitInput} from '../WidgetUserCreation/components/WidgetOrgUnitInput'
import {WidgetPermissionInput} from '../WidgetUserCreation/components/WidgetPermissionInput'
import {customerRoleSetup} from '../WidgetUserCreation/utils/CustomerRoleSetup'
import {WidgetUserCreationFormData} from '../WidgetUserCreation/WidgetUserCreation.form'
import {useWidgetUserCreationStyles} from '../WidgetUserCreation/WidgetUserCreation.styles'
import {
  CustomerToggleBasedPermissionKeys,
  CustomerToggleBasedPermissions,
  OrgUnit
} from '../WidgetUserCreation/WidgetUserCreation.types'

import {InviteUser} from './components/InviteUser'
import {ResetPassword} from './components/ResetPassword'
import {UserDetailsField} from './components/UserDetailsField'
import {UserForm} from './components/UserForm'
import {Header} from './header/Header'
import {useCreateUserRoles} from './hooks/useCreateUserRoles'
import {useStyles} from './QuickPreview.styles'
import {QuickPreviewSkeleton} from './QuickPreviewSkeleton'

export const QuickPreview = () => {
  const {t} = useTranslation()
  const {classes} = useStyles()
  const loggedInUserProfile = useSelector(selectLoggedInUserProfile)
  const {classes: widgetUserCreationClasses} = useWidgetUserCreationStyles()

  const [cscData, setCscData] = useState<RoleAssignment>()
  const {data: loggedInUserRoles} = useUserRoles(loggedInUserProfile?.user_id)
  const [selectedOrgUnit, setSelectedOrgUnit] = useState<OrgUnit>()
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | undefined>()
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState<string | undefined>()

  const {userId} = useParams<{userId: string}>()
  const {mutate: addPermission, isLoading: isLoadingPermissions} = useCreateUserRoles()
  const {
    data: user,
    refetch: refetchUser,
    isLoading: isUserLoading,
    isError: isUserError
  } = useUser(userId)

  const {data: roles, isLoading: isRolesLoading, isError: isRolesError} = useUserRoles(user?.id)

  const initPermissions = {
    [CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries]: true,
    [CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders]: false,
    [CustomerToggleBasedPermissionKeys.canSeeInvoices]: false
  }
  const [selectedPermissions, setSelectedPermissions] =
    useState<CustomerToggleBasedPermissions>(initPermissions)
  const permissionFormMethods = useForm<WidgetUserCreationFormData>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      mobileNumber: '',
      customerId: '',
      orgUnit: '',
      products: [],
      permissions: selectedPermissions
    }
  })
  const [isEditing, setEditing] = useState(false)
  const [displayPermissionConfirmation, setDisplayPermissionConfirmation] = useState(false)
  const userForm = useMemo(
    () => ({
      name: user?.name ?? '',
      eMail: user?.eMail ?? '',
      mobileNumber: user?.mobileNumber ?? ''
    }),
    [user]
  )

  const formMethods = useForm<UserInformationFormData>({
    defaultValues: userForm,
    mode: 'all',
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    if (selectedCustomerId && !selectedCustomerNumber) {
      permissionFormMethods.resetField('customerId', {defaultValue: ''})
      setSelectedCustomerId(undefined)
    }
  }, [selectedCustomerNumber])

  useEffect(() => {
    if (selectedOrgUnit && selectedCustomerNumber) {
      permissionFormMethods.resetField('customerId', {defaultValue: ''})
      setSelectedCustomerNumber(undefined)
    }
  }, [selectedOrgUnit])

  useEffect(() => {
    if (loggedInUserRoles && loggedInUserRoles?.length > 0) {
      const cscRole = loggedInUserRoles?.find(
        (role) => role.roleType === 'CUSTOMER_SERVICE_CENTER' || role.roleType === 'SALES_AGENT'
      )
      setCscData(cscRole)
    }
  }, [loggedInUserRoles])

  useEffect(() => {
    userForm && formMethods.reset(userForm)
  }, [formMethods, userForm])

  const {handleUpdateUser} = useUpdateUser({setEditing, formMethods, refetchUser, userData: user})

  const handleFormMode = (edit: boolean) => {
    setEditing(edit)
  }

  const handleCancel = () => {
    formMethods.reset(userForm)
  }

  const handleRedirect = () => {
    window.open(`${window.location.origin}/manage/${userId}`, '_blank')
  }

  if (isUserLoading || isRolesLoading) {
    return <QuickPreviewSkeleton />
  }

  if (isUserError || isRolesError) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        {t('error.notFound')}
      </Box>
    )
  }

  const handleSubmitForm = async () => {
    const assignUserRolesData: RoleAssignment[] = customerRoleSetup(selectedPermissions).map(
      (roleType) => ({
        userId: user?.user_id || '',
        id: -1,
        roleType: roleType as RoleType,
        dataScope: {
          countryId: cscData?.dataScope['countryId'] || '',
          businessLine: cscData?.dataScope['businessLine'] || '',
          orgUnitId: selectedOrgUnit?.value || '',
          customerIds: [selectedCustomerId || '']
        }
      })
    )

    addPermission(assignUserRolesData)
  }

  return (
    <Content>
      <Page variant="sheet" className={classes.page}>
        <Header userId={userId} isEditing={isEditing} handleFormMode={handleFormMode} />
        {user && (
          <UserForm
            user={user}
            formMethods={formMethods}
            handleUpdateUser={handleUpdateUser}
            handleCancel={handleCancel}
            isEditing={isEditing}
            toggleEdit={() => setEditing(!isEditing)}
            isUserLoading={isUserLoading}
          />
        )}
        <UserDetailsField roles={roles} isRolesLoading={isRolesLoading} />
        <InviteUser user={user} refetchUser={refetchUser} />
        <form onSubmit={permissionFormMethods.handleSubmit(handleSubmitForm)}>
          <Typography
            style={{fontSize: '18px', fontWeight: 600, marginBottom: '14px', marginTop: '48px'}}
          >
            {t('quickPreview.addPermissions.title')}
          </Typography>
          {!displayPermissionConfirmation && (
            <Button
              variant="text"
              color="primary"
              onClick={() => setDisplayPermissionConfirmation(true)}
              data-test-id={`add-permissions-btn-${userId}`}
              startIcon={<Add style={{color: '#016AD4', fontSize: '16px'}} />}
              style={{
                width: 'auto',
                marginLeft: '-16px',
                color: '#016AD4',
                fontWeight: 500
              }}
            >
              {t('quickPreview.addPermissions.title')}
            </Button>
          )}
          {displayPermissionConfirmation && (
            <>
              <Box
                style={{display: 'flex', flexDirection: 'column', gap: '8px', marginBottom: '24px'}}
              >
                <WidgetPermissionInput
                  permissionKey={CustomerToggleBasedPermissionKeys.canSeeOrderAndDeliveries}
                  selectedPermissions={selectedPermissions}
                  setSelectedPermissions={setSelectedPermissions}
                  defaultValue={true}
                  disabled={true}
                />
                <WidgetPermissionInput
                  permissionKey={CustomerToggleBasedPermissionKeys.canCreateAndChangeOrders}
                  selectedPermissions={selectedPermissions}
                  setSelectedPermissions={setSelectedPermissions}
                />
                <WidgetPermissionInput
                  permissionKey={CustomerToggleBasedPermissionKeys.canSeeInvoices}
                  selectedPermissions={selectedPermissions}
                  setSelectedPermissions={setSelectedPermissions}
                />

                <WidgetOrgUnitInput
                  formMethods={permissionFormMethods}
                  cscData={cscData}
                  selectedOrgUnit={selectedOrgUnit}
                  setSelectedOrgUnit={setSelectedOrgUnit}
                />
                <WidgetCustomerInput
                  formMethods={permissionFormMethods}
                  selectedCustomerNumber={selectedCustomerNumber}
                  setSelectedCustomerId={setSelectedCustomerId}
                  selectedOrgUnit={selectedOrgUnit}
                  cscData={cscData}
                  setSelectedCustomerNumber={setSelectedCustomerNumber}
                />
              </Box>
              <Box
                style={{display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '12px'}}
              >
                <Button
                  type="button"
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setSelectedPermissions(initPermissions)
                    setDisplayPermissionConfirmation(false)
                  }}
                  disabled={permissionFormMethods.formState.isSubmitting}
                  style={{color: '#016AD4', marginLeft: '-16px'}}
                >
                  {t('quickPreview.discardChanges')}
                </Button>
                <LoadingButton
                  type="submit"
                  style={{marginTop: 0}}
                  btnClassName={widgetUserCreationClasses.submitButton}
                  progressClassName={widgetUserCreationClasses.progress}
                  loading={isLoadingPermissions}
                  disabled={
                    !isEmpty(permissionFormMethods.formState.errors) ||
                    !permissionFormMethods.formState.isValid ||
                    !selectedCustomerId
                  }
                >
                  <Typography
                    style={{
                      textTransform: 'none',
                      fontSize: '16px',
                      fontWeight: 500,
                      letterSpacing: 0,
                      color: '#FFFFFF'
                    }}
                  >
                    {t('quickPreview.addPermissions.title')}
                  </Typography>
                </LoadingButton>
              </Box>
            </>
          )}
        </form>
        <ResetPassword user={user} />
        <Button
          variant="outlined"
          color="primary"
          onClick={handleRedirect}
          data-test-id={`full-user-overview-btn-${userId}`}
          startIcon={<Launch style={{color: '#29333D', fontSize: '16px'}} />}
          style={{
            width: '100%',
            marginTop: '12px',
            color: '#29333D',
            fontWeight: 500,
            border: '1px solid #E8ECF0',
            borderRadius: '4px'
          }}
        >
          {t('quickPreview.fullUserOverview')}
        </Button>
      </Page>
    </Content>
  )
}
