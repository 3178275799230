import {Item} from '@hconnect/common/Invite/Create/components/LookupDropdown'
import {ErrorPaper, SelectDropdown} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'
import {Controller} from 'react-hook-form'
import {useTranslation} from 'react-i18next'

import {useOrgUnit} from '../../../hooks/useOrgUnit'
import {RoleAssignment} from '../../../modules/ManageUsers.selectors'
import {useWidgetUserCreationStyles} from '../WidgetUserCreation.styles'
import {OrgUnit} from '../WidgetUserCreation.types'

import {WidgetInputsProps} from './WidgetInputs.types'

type WidgetOrgUnitInputProps = WidgetInputsProps & {
  cscData?: RoleAssignment
  selectedOrgUnit?: OrgUnit
  setSelectedOrgUnit: (orgUnit?: OrgUnit) => void
}
export const WidgetOrgUnitInput = ({
  formMethods,
  cscData,
  selectedOrgUnit,
  setSelectedOrgUnit
}: WidgetOrgUnitInputProps) => {
  const {t} = useTranslation()
  const {classes} = useWidgetUserCreationStyles()

  const {
    data: orgUnitsData,
    isLoading: isLoadingOrgUnits,
    isError: isOrgUnitsError
  } = useOrgUnit(
    cscData?.dataScope.countryId as string,
    cscData?.dataScope.businessLine as string,
    false
  )

  return (
    <Controller
      name="orgUnit"
      control={formMethods.control}
      rules={{
        required: {
          value: true,
          message: t('widgetUserCreation.form.errors.required', {
            fieldName: t('widgetUserCreation.form.orgUnit')
          })
        }
      }}
      render={({field}) => (
        <SelectDropdown
          {...field}
          data-test-id="org-unit-dropdown"
          label="Org Unit"
          className={classes.selectDropdown}
          errorRender={() => <ErrorPaper variant="primaryDark" />}
          selectedProps={{style: {fontWeight: 400}}}
          showError={isOrgUnitsError}
          loading={isLoadingOrgUnits}
          options={orgUnitsData?.map((orgUnit) => ({
            value: orgUnit.key,
            label: orgUnit.label
          }))}
          renderItem={(item: Item) => (
            <Box style={{display: 'flex', flexDirection: 'column'}}>
              <Box>{item.label}</Box>
              <Box>{item.value}</Box>
            </Box>
          )}
          stringifyItem={(item: Item) => item.value}
          renderChosenItem={(item: Item) => item.label}
          selectedItem={selectedOrgUnit}
          popoverStyle={{left: 0}}
          onChange={(item) => {
            setSelectedOrgUnit(item)
            field.onChange(item.value)
          }}
        />
      )}
    />
  )
}
